import axios from "axios";
import urls from "../constant/url.js";

axios.interceptors.request.use(
    function(successfulReq) {
        let token =  'bearer ' + localStorage.getItem("atToken");
        successfulReq.headers['authorization'] = token;
        return successfulReq;
    }, 
    function(error) {
      return Promise.reject(error);
    }
  );

const userService = {

    getUser : async(queryData) => {
        try{
            let url = urls.apiURL+"user?page="+queryData.page+"&pageSize="+queryData.pageSize;
            if(queryData.search){
                url = url + "&search=" + queryData.search;
            }
            let result = await axios.get(url);
            return result;
        }
        catch(err){
            return err.response;
        }
    },

    getUSerByID : async(id) => {
        try{
            let result = await axios.get(urls.apiURL+"user/"+id);
            return result;
        }catch(err){
            return err.response;
        }
    },

    postUSer : async(userData) => {
        try{
            let result = await axios.post(urls.apiURL+"user/create/",userData);
            return result;
        }catch(err){
            return err.response;
        }
    },

    updateUSer: async(id,editedUser) => {
        try {
            let result = await axios.put(urls.apiURL+"user/"+id,editedUser);
            return result ;
        }catch(err) {
            return err.response;
        }
    },

    deleteUSer: async(id) => {
        try {
            let result = await axios.delete(urls.apiURL+"user/"+id);
            return result ;
        }catch(err) {
            return err.response;
        }
    }
}

export default userService;