import PostPage from "../Pages/postPage";
import CreatePage from "../Pages/createPage";
import UserMgmtPage from "../Pages/userMgmtPage.jsx";
import LogoutPage from "../Pages/auth/logoutPage.jsx";

import InboxIcon from '@material-ui/icons/MoveToInbox';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import GroupIcon from '@material-ui/icons/Group';
import LocalParkingIcon from '@material-ui/icons/LocalParking';
import UserRole from "../constant/userRole.js";
const routes =[
    {
        main:"/dashboard",
        name : "Create Page",
        path : "/create/",
        component : CreatePage,
        showSidebar : false,
        icon : <InboxIcon/>,
        minRole : UserRole.writer,

    },

    {
        main : "/dashboard",
        name : "Create Page",
        path : "/create/:id",
        component : CreatePage,
        showSidebar : false,
        icon : <InboxIcon/>,
        minRole : UserRole.writer,
    },

    {
        main:"/dashboard",
        name : "Post Management",
        path : "/",
        component : PostPage,
        showSidebar : true,
        icon : <LocalParkingIcon/>,
        minRole : UserRole.writer,
    },

    {
        main:"/dashboard",
        name : "User Management",
        path : "/user",
        component : UserMgmtPage,
        showSidebar : true,
        icon : <GroupIcon/>,
        minRole : UserRole.admin,
    },

    {
        main : "/dashboard",
        name : "Logout",
        path : "/logout",
        component : LogoutPage,
        showSidebar : true,
        icon : <PowerSettingsNewIcon/>,
        minRole : UserRole.writer,
    }

]

export default routes;