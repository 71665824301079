import axios from "axios"
import urls from "../constant/url";
const authService = {

    loginUser : async (loginData)=>{
        try {
            let result = await axios.post(urls.apiURL+"auth",loginData);
            return result;
        }
        catch (err){
            return err.response;
        }
    },

    verifyToken : async()=>{
        try{
            let token ="bearer " + localStorage.getItem("atToken");
            let result = await axios.post(urls.apiURL+"auth/verify",{token});
            console.log(result);
            return result;
        }
        catch(err){
            return err.response;
        }
    }

}

export default authService;