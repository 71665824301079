import React,{ useEffect } from "react";
function LogoutPage(props){
    useEffect(()=>{
        let result = localStorage.removeItem("atToken");
        props.history.push("/auth/login");
        props.logout();

    })

    return <div>
    </div>
}

export default LogoutPage;