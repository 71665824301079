import React from "react";
import Loading from "../../Assesst/Loading.gif";

function LoadingPage(props){
    return <div className="loading">

        <img src={Loading} className="img-fluid"></img>

    </div>
}

export default LoadingPage;