import userRole from "../constant/userRole";
import LoginPage from "../Pages/auth/loginPage";

const loginRoute = [
    {
        main : "/auth",
        name : "Login",
        path : "/login",
        component : LoginPage,
        
    }
]

export default loginRoute;