import React from "react";
import ReactQuill,{Quill} from 'react-quill';
import {
    Row, Col,Container,Button,Modal,Badge, Jumbotron,Input
} from "react-bootstrap";
import postService from "../Services/postService.js";
import {toast } from "react-toastify";
import TextField from '@material-ui/core/TextField';
import LoadingOverlay from 'react-loading-overlay';
import DatePicker from "react-datepicker";
import { TablePagination } from "@material-ui/core";

var Image = Quill.import('formats/image');
Image.className = 'img-fluid';
Quill.register(Image, true);

class CreatePage extends React.Component{

    constructor(props){
        super(props);
        this.state={
            name:"",
            title:"",
            shortDesc:"",
            text : "",
            tagName:"",
            image : "",
            tagsArray:[],
            id:null,
            isActive : false,
            showFeature : false,
            startDate :  new Date(),
            reference : "",
            referenceArray : [],
            bannerText : "",
            bannerImage : "",
            preview : "",
            bannerPreview :"",
        }

        this.quill = React.createRef();
    }

    async componentDidMount(){
        console.log(this.props);
        if(this.props.match.params.id){
            let id = this.props.match.params.id;
            let result = await postService.getArticlebyId(id);
            console.log(result);
            if(result.data.success == true){
                let response = result.data.article;
                console.log(response);
                this.setState({
                    name : response.name,
                    title : response.title,
                    shortDesc : response.description,
                    text : response.excerpt,
                    tagsArray : response.tags,
                    id,
                    referenceArray : response.refs,
                    bannerText : response.bannerText,
                    image : response.image || {},
                    bannerImage : response.bannerImage || {},
                })
            }
        }

    }

    handleQuill=(html)=>{
        this.setState({
            text: html
        })
       
    }

    handleChange=(e)=>{
        this.setState({
            [e.target.name] : e.target.value
        })
    }


    handleImage=(e)=>{
        let previewName;
        if(e.target.name ==  "image"){
            previewName = "preview";
        }else {
            previewName="bannerPreview";
        }
        this.setState({
            [e.target.name] : e.target.files,
            [previewName]: URL.createObjectURL(e.target.files[0]),
        })
    }

    handleTagAdd=()=>{
        let tagsArray = this.state.tagsArray;
        tagsArray.push(this.state.tagName);
        this.setState({
            tagsArray,
            tagName:""
        })
    }

    uploadImage = async (image) => {
        let formData = new FormData();
        formData.append('image',image[0]);

        let imageResult = await postService.uploadImage(formData);

        if(imageResult.data.success == false){
            toast.error(imageResult.data.message);
            this.setState({
                isActive : false
            })
            return false;          
        }else {
            return imageResult.data.url ;
        }
    }

    handlePost = async ()=>{
        this.setState({
            isActive : true
        })

        let imageURL = await this.uploadImage(this.state.image);
        if (!imageURL) return;

        let bannerImgUrl = await this.uploadImage(this.state.bannerImage);
        if (!bannerImgUrl) return ;

        let postData = {
            name : this.state.name,
            title : this.state.title,
            description : this.state.shortDesc,
            excerpt : this.state.text,
            tags : this.state.tagsArray,
            image : imageURL,
            refs : this.state.referenceArray,
            bannerImage : bannerImgUrl,
            bannerText : this.state.bannerText,
        }

        let result = await postService.postArticle(postData);

        if(result.data.success == false){
            toast.error(result.data.message);
            this.setState({
                isActive : false

            })
        }else{
            console.log(result);
            this.setState({
                name:"",
                title:"",
                shortDesc:"",
                text : "",
                tagName:"",
                tagsArray:[],
                isActive : false,
                reference : "",
                referenceArray:[],
                bannerImage : "",
                bannerText : "",
                image : "",
                id : result.data.article._id,
                showFeature : true,
            })
            toast.success("Posted Successfully..")
        }
    }

    editPost = async () => {
        this.setState({
            isActive : true
        })
        
        let imageURL = await this.uploadImage(this.state.image);
        if (!imageURL) return;

        let bannerImgUrl = await this.uploadImage(this.state.bannerImage);
        if (!bannerImgUrl) return ;

        let editedData={
            name : this.state.name,
            title : this.state.title,
            description : this.state.shortDesc,
            excerpt : this.state.text,
            tags : this.state.tagsArray,
            refs : this.state.referenceArray,
            bannerText : this.state.bannerText,
            image: imageURL,
            bannerImage: bannerImgUrl,
        }

        let result = await postService.updateArticle(this.state.id, editedData);
        if (!result.success){
            toast.error(result.data.message);
           
        }else{
            this.setState({
                showFeature : true
            })
        }
        this.setState({
            isActive : false
        })
    }

    handleCancelEdit=()=>{
        this.props.history.push("/dashboard/");
    }

    setStartDate=(date)=>{
        this.setState({
            startDate : date
        })
    }

    handleFeature= async()=>{

        let featureTill = new Date(this.state.startDate).getTime();
        let result = await postService.featureArticle(this.state.id,featureTill);
        console.log(result);
        if(result.data.success ==  true){
            toast.success(result.data.message);
            this.setState({
                showFeature : false
            })
             this.props.history.push("/dashboard/");
        }
    }

    imageHandler=async()=>{
        const input = document.createElement('input');

        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();

        input.onchange = async () => {
            const file = input.files[0];
            const formData = new FormData();

            formData.append('image', file);

            // Save current cursor state
            console.log(this.quill);
            const range = this.quill.getEditor().getSelection(true);

            // Insert temporary loading placeholder image
            this.quill.getEditor().insertEmbed(range.index, 'image', `${window.location.origin}/images/loaders/placeholder.gif`);

            // Move cursor to right side of image (easier to continue typing)
            this.quill.getEditor().setSelection(range.index + 1);

            const res = await postService.uploadImage(formData); // API post, returns image location as string e.g. 'http://www.example.com/images/foo.png'
            console.log(res);
            // Remove placeholder image
            this.quill.getEditor().deleteText(range.index, 1);

            // Insert uploaded image
            // this.quill.insertEmbed(range.index, 'image', res.body.image);
            this.quill.getEditor().insertEmbed(range.index, 'image', res.data.url);
        };
    }

    handleDeleteTag=(e,tag)=>{
        console.log("tag deleted");
        console.log(tag);

        var filteredTag = this.state.tagsArray.filter((item)=>{
            return (item != tag);
        })
        this.setState({
            tagsArray : filteredTag
        })
    }

    handleDraft=async()=>{
        this.setState({
            showFeature : false
        })

        var result= await postService.draftArticle(this.state.id);
        console.log(result);
        
        this.props.history.push("/dashboard/");
    }

    handleReference=async()=>{
        let result = await postService.postReference(this.state.reference);
        console.log(result);
        
        if(result.data.success == false) {
            toast.error(result.data.message);
        }else{
            let referenceData = result.data.data;
            let referenceArray = this.state.referenceArray;
            referenceArray.push(referenceData);
            this.setState({
                referenceArray,
                reference :"",
            })
        }
        
    }

    removeReference=(e,url)=>{
        console.log(url);
        let filteredRef = this.state.referenceArray.filter((item)=>{
            return (item.url != url)
        })

        this.setState({
            referenceArray : filteredRef
        })
    }

    render (){
        return <div>
            <LoadingOverlay active={this.state.isActive} spinner text='Saving your content.'>
            <Container>
               <Row>
                   <Col md={1}></Col>
                   <Col md={10}>
                   <Row>
                   <Col md={6}>
                        <TextField type="text" onChange={this.handleChange} name="name" 
                        required id="outlined-required" label="Enter Name" 
                        value={this.state.name} className="width100"/>
                   </Col>
               
                   <Col md={6}>
                        <TextField type="text" onChange={this.handleChange} name="title" 
                         required id="outlined-required" label="Enter Title" 
                        value={this.state.title} className="width100"/>
                   </Col>
                </Row>
                <br></br>
                <Row>
                   <Col>
                        <TextField type="text" onChange={this.handleChange} name="shortDesc" 
                        value={this.state.shortDesc} required id="outlined-required" 
                        label="Enter Description"  className="width100"/>
                   </Col>
                </Row>
                <br></br>
                <Row>
                   <Col md={10}>
                        <TextField type="text" onChange={this.handleChange} value={this.state.tagName} 
                        required id="outlined-required" label="Enter Tags" 
                        name="tagName"  className="width100" />
                   </Col>

                   <Col md={2}>
                        <Button onClick={this.handleTagAdd} className="width100">Add</Button>
                   </Col>
                </Row>
                <br></br>
                <Row>
                    <Col className="textA-L">
                    {this.state.tagsArray.map((tag)=>{
                        return <Badge variant="info" className="marginLR-5 hoverRed" 
                        onClick={(e)=>this.handleDeleteTag(e,tag)} value={this.state.tagName}>
                            <div className="padding-10">{tag}</div>
                        </Badge>
                    })}
                    </Col>
                </Row>
                <br></br>


                <Row>
                    <Col className="textA-L" md={6}>
                    Image :    
                    <br></br>
                    <input
                        type="file"
                        multiple={false}
                        onChange={this.handleImage} 
                        value={this.state.image?.name}
                        name="image"
                        allow='image/png,image/jpg,image/jpeg'
                    />
                    </Col>

                    <Col md={6}>
                        <img src={this.state.preview} className="image"></img>
                    </Col>
                </Row>
                <br></br>

                <Row>
                    <Col md={12} >
                        <TextField type="text" onChange={this.handleChange} name="bannerText" required
                        value={this.state.bannerText} label="Enter banner text" className="width100"></TextField>
                    </Col>
                </Row>
                <br></br>

                <Row>
                    <Col md={6} className="textA-L">
                        Banner Image : 
                        <br></br>
                        <input type="file" onChange={this.handleImage} multiple={false}
                        value={this.state.bannerImage ? this.state.bannerImage.name : null } 
                        name="bannerImage" allow='image/png,image/jpg,image/jpeg'></input>
                    </Col>
                    <Col md={6}>
                        <img src={this.state.bannerPreview} className="image"></img>
                    </Col>
                </Row>
                    <br></br>
                <Row>
                    <Col>
                        <ReactQuill 
                            value={this.state.text} 
                            ref={el => {
                                this.quill = el;
                            }}
                            modules={
                                {
                                    toolbar:{
                                        container: [
                                            [ "bold", "italic", "underline", "strike" ],
                                            [ "link", "image", "video" ],
                                            [ "blockquote" ],
                                            [ {"header": [1,2,3,4,5,6,false]} ],
                                            [ {"list":"ordered"}, {"list":"bullet"} ],
                                            [ {"indent":"-1"}, {"indent":"+1"} ],
                                            [ {"direction":"rtl"} ],
                                            [ {"size":["small",false,"large","huge"]} ],
                                            [ "clean" ]
                                        ],
                                        handlers: {
                                            image: this.imageHandler
                                        }
                                    }    
                                }                          
                            }
                            onChange={this.handleQuill} /> 
                    </Col>
                </Row>   
                <br></br>

                <Row>
                   <Col md={10}>
                        <TextField type="text" onChange={this.handleChange} value={this.state.reference} 
                        id="outlined-required" label="Insert Reference" 
                        name="reference"  className="width100" />
                   </Col>

                   <Col md={2}>
                        <Button onClick={this.handleReference} className="width100">Insert</Button>
                   </Col>
                </Row>
                <br></br>
                {this.state.referenceArray.length == 0 ?
                <></>
                :
                <p>References</p>
                }

                {this.state.referenceArray.map((item)=>{
                    return <Row className="marginBtm-10">
                        <Col md={5}>
                            <img src={item.image} className="img-fluid"></img>
                        </Col>
                        {/* <Col md={7} className="textAlign-left">
                            <div className="clearfix ">
                                <a href={item.url} target="_blank" className="float-left">{item.title}</a>
                                <span className="float-right removeReference" onClick={(e)=>this.removeReference(e,item.url)}>x</span><br></br>
                            </div>
                        </Col> */}
                        <Col md={7}>
                            <Row>
                                <Col md={10} className="textAlign-left">
                                    <a href={item.url} target="_blank">{item.title}</a>
                                    <p>{item.name}</p>
                                </Col>
                                <Col md={2}>
                                    <Button variant="danger" size="sm" onClick={(e)=>this.removeReference(e,item.url)}>x</Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                })}
                
                <br></br>
                <Row>
                    <Col md={8}></Col>
                    
                    {this.state.id ? 
                    <>
                        <Col md={2}>
                        <Button variant="danger" className="width100" onClick={this.handleCancelEdit}>Cancel</Button>
                        </Col> 
                        
                        <Col md={2}>
                        <Button className="width100" onClick={this.editPost}>Edit</Button>
                        </Col>

                    </>
                        :    
                        <>
                        <Col md={2}>
                        </Col>  
                        <Col md={2}>           
                        <Button className="width100" onClick={this.handlePost}>Post</Button>
                        </Col>
                        </>
                    }
                </Row> 
                   </Col>
                   <Col md={1}></Col>
               </Row>
               
             
           </Container>
           </LoadingOverlay>

           <Modal show={this.state.showFeature} onHide={this.handleClose}>
               <Modal.Header>Feature Article</Modal.Header>
               <Modal.Body>
                    Do you want to feature this article?
                    <div>
                    <DatePicker
                    selected={this.state.startDate}
                    onChange={date => this.setStartDate(date)}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    timeCaption="time"
                    dateFormat="MMMM d, yyyy h:mm aa"
                    minDate={(new Date())}
                    />
                    </div>
               </Modal.Body>
               <Modal.Footer>
                   <Button variant="primary"  onClick={this.handleFeature}>Feature</Button>
                   <Button variant="secondary" onClick={this.handleDraft}>Draft</Button>
               </Modal.Footer>
           </Modal>

        </div>
    }
}

export default CreatePage;