import React from "react";
import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import tableIcons from "../constant/icons";
import userService from "../Services/userService.js";
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import TextField from '@material-ui/core/TextField';
import {toast } from "react-toastify";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import UserRole from "../constant/userRole.js";
import { 
    Modal,Button,Row ,Col, Container,Form, ModalFooter
} from 'react-bootstrap';

class UserMgmtPage extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            username : "",
            email : "",
            password1 : "",
            password2 : "",
            users:[],
            show : false,
            id : null,
            deleteModal : false,
            hidden : true,
            userType : UserRole.writer,
        }
        this.tableRef = React.createRef();
    }

    loadUser= async (query)=>{
        return new Promise(async(resolve, reject) => {
            let queryJSON ={
                page : query.page,
                pageSize : query.pageSize,
                search : query.search,
            } 

        let result = await userService.getUser(queryJSON);
        console.log(result);
        if(result.data.success == true){
            let user = result.data.users;
            user.forEach((item)=>{
                if(item.role == UserRole.writer){
                    item.userRole = "Writer"
                }else{
                    item.userRole = "Admin"
                }
            })
            this.setState({
                users : user
            })
        }

        resolve({
            data: this.state.users,// your data array
            page: query.page,// current page number
            totalCount: result.data.totalCount,// total row number
        });
    })

    }

    refreshData=()=>{
        this.tableRef.current && this.tableRef.current.onQueryChange()
    }

    gotoCreate=()=>{
        this.setState({
            show : true
        })
    }

    handleClose=()=>{
        this.setState({
            show : false,
            deleteModal : false,
            username : "",
            email : "",
            password1 : "",
            password2 : "",
            id : null,
        })
    }

    handleChange=(e)=>{
        this.setState({
            [e.target.name] : e.target.value
        })
    }
    handleSaveUser = async ()=>{
        let userData = {
            username : this.state.username,
            email : this.state.email,
            password1 : this.state.password1,
            password2 : this.state.password2,
            role : this.state.userType,
        }

        let result =  await userService.postUSer(userData);

        if(result.data.success == true){
            toast.success(result.data.message);
            this.setState({
                username : "",
                email : "",
                password1 : "",
                password2 : "",
                show : false,
                userType : UserRole.writer,
            })
            this.refreshData();
        }else{
            toast.error(result.data.message);
        }
    }

    handleEditUser= async(e,id)=>{
      
        let result = await userService.getUSerByID(id);
        if(result.data.success == true){
            this.setState({
                show: true,
                id,
                username : result.data.data.username,
                email : result.data.data.email,
                userType : result.data.data.role,
            })
        }
    }

    handleDeleteUserModal=(e,id)=>{
        this.setState({
            id,
            deleteModal : true
        })
    }

    handleDeleteUser= async()=>{
        let result = await userService.deleteUSer(this.state.id);
        console.log(result);
        if(result.data.success == true) {
            toast.success(result.data.message);
            this.setState({
                deleteModal : false
            })
            this.refreshData();
        }else{
            toast.error(result.data.message);
        }
    }

    handleUpdateUser= async()=>{
        
        let editedUser = {
            username : this.state.username,
            email : this.state.email,
            password1 : this.state.password1,
            password2 : this.state.password2,
            role : this.state.userType,
        }

        let response = await userService.updateUSer(this.state.id,editedUser);
        console.log(response);

        if(response.data.success == true) {
            toast.success(response.data.message);
            this.setState({
                show : false,
                id : null,
                username : "",
                email : "",
                password1 : "",
                password2 : "",
                userType  : UserRole.writer,
            })
            this.refreshData();

        }else{
            toast.error(response.data.message)
        }
    }

    toggleShow=()=>{
        this.setState({
            hidden : !this.state.hidden
        })
    }

    handleUserType=(e)=>{
        this.setState({
            userType : e.target.value
        })
    }

    render() {
        return <div>
            <MaterialTable
                tableRef={this.tableRef}
                columns={[
                    { title : "Name" , field : "username"},
                    { title : "Email" , field : "email"},
                    { title : "Type" , field : "userRole"}
                ]}

                  actions={[
                    {
                        icon: AddBox,
                        isFreeAction:true,
                        tooltip: ' Add User ',
                        onClick: (event, rowData) => {
                        this.gotoCreate();
                        }
                    },

                    {
                        icon: EditIcon,
                        tooltip: 'Edit User',
                        onClick: (e, rowData) => {
                        this.handleEditUser(e,rowData._id)
                        }
                    },

                    {
                        icon: DeleteOutlineIcon,
                        tooltip: 'Delete User',
                        onClick: (e, rowData) => {
                        this.handleDeleteUserModal(e,rowData._id)
                        }
                    },

                    ]}
                    icons={tableIcons}
                    title="User Management"
                    data={this.loadUser}
                    options={{
                        debounceInterval : 1000,
                        search : true,
                        pageSize : 5,
                        headerStyle: {
                            backgroundColor: '#01579b',
                            color: '#FFF'
                          }
                    }}
            />

            <Modal show={this.state.show} onHide={this.handleClose}>
                <Modal.Header>
                    {this.state.id ?
                        <b>Edit User</b>
                        :
                        <b>Create New User</b>
                    }
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col md={12}>
                                <Row>
                                    <Col md={6}>
                                        <TextField type="text" onChange={this.handleChange} name="username" value={this.state.username}
                                        required id="outlined-required" label="Enter User Name" className="width100"/>
                                    </Col>

                                    <Col md={6}>
                                        <TextField type="email" onChange={this.handleChange} name="email" value={this.state.email}
                                        required id="outlined-required" label="Enter Email " className="width100"/>
                                    </Col>
                                </Row>

                                <Row className="marginTop mb-2">
                                    <Col md={6}>
                                        <TextField type={this.state.hidden ? "password" : "text"} onChange={this.handleChange} name="password1" value={this.state.password1}
                                        required id="outlined-required" label="Enter Password" className="width100"/>
                                    </Col>

                                    <Col md={6}>
                                        <TextField type={this.state.hidden ? "password" : "text"} onChange={this.handleChange} name="password2" value={this.state.password2}
                                        required id="outlined-required" label="Confirm Password" className="width100"/>

                                    </Col>

                                </Row>
                                <br></br>

                                <Row>
                                    <Col>
                                    <Form.Group controlId="exampleForm.ControlSelect2">
                                        <Form.Label>Type</Form.Label>
                                        <Form.Control as="select" value={this.state.userType} 
                                        name= "userType" onChange={this.handleUserType}>
                                            <option value = {UserRole.writer}>Writer</option>
                                            <option value = {UserRole.admin}>Admin</option>
                                        </Form.Control>
                                    </Form.Group>
                                    </Col>
                                </Row><br></br>
                            
                                <Row>
                                    <Col>
                                    {this.state.hidden == true ?
                                        <div>
                                            <VisibilityIcon className="showPWicon" onClick={this.toggleShow}></VisibilityIcon>
                                            <span className="showPW" onClick={this.toggleShow}>Show Password</span>
                                        </div>
                                        :
                                        <div>
                                            <VisibilityOffIcon className=" showPWicon" onClick={this.toggleShow}></VisibilityOffIcon>
                                            <span className="showPW" onClick={this.toggleShow}>Hide Password</span>
                                        </div>
                                    }
                                    </Col>
                                </Row>


                            </Col>
                        </Row>

                    </Container>
                </Modal.Body>

                <Modal.Footer>

                    <Button variant="danger" onClick={this.handleClose}>
                        Close
                    </Button>
                    {this.state.id ?
                        <Button variant="primary" onClick={this.handleUpdateUser}>Edit</Button>
                       :
                       <Button variant="primary" onClick={this.handleSaveUser}>Create</Button>
                    }
                 
                   
                </Modal.Footer>

            </Modal>

            <Modal show={this.state.deleteModal} onHide={this.handleClose}>
                <Modal.Header>
                    <b>Confirmation Required</b>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to delete this user?</p>
                </Modal.Body>

                <Modal.Footer>

                    <Button variant="primary" onClick={this.handleClose}>
                        Close
                    </Button>

                    <Button variant="danger" onClick={this.handleDeleteUser}>
                        Delete
                    </Button>

                </Modal.Footer>
            </Modal>
        </div>
    }
}

export default UserMgmtPage;