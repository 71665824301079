import React from "react";
import MyContext from './myContext';

class MyProvider extends React.Component {
    state = {
        currentUser : {},

    };

    render() {
        return (
            <MyContext.Provider
                value={{
                    currentUser: this.state.currentUser,
                    login : (userData)=>{
                        this.setState({
                            currentUser:userData
                        })
                    },
                    logout : () =>{
                        this.setState({
                            currentUser : {}
                        })
                    }
                }}
            >
                {this.props.children}
            </MyContext.Provider>
        );
    }
}

export default MyProvider;