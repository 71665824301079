import Layout from './Layout/layout';
import { 
  BrowserRouter as Router, Route, Link, Switch, Redirect 
} from "react-router-dom";
import 'react-quill/dist/quill.snow.css'; 
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthLayout from './Layout/authLayout';
import { createBrowserHistory } from "history";
import MyProvider from './context/myProvider';
import MyContext from './context/myContext';

const history = createBrowserHistory();

function App() {
  return (
    <MyProvider>
    <div className="App">
      <MyContext.Consumer>
        {context =>{
          return <Router history={history}>
          <Switch>
            <Route path="/auth" render={(props)=><AuthLayout {...props} currentUser={context.currentUser} login = {context.login} logout = {context.logout}/>} ></Route>
            <Route path="/dashboard" render={(props)=><Layout {...props} currentUser = {context.currentUser} login = {context.login} logout = {context.logout}/>}></Route>
            <Redirect to="/auth/login"></Redirect>
          </Switch>
          <ToastContainer />
        </Router>
        }
      }
      </MyContext.Consumer>
    </div>
    </MyProvider>
  );
}

export default App;
