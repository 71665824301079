import React from "react";
import MaterialTable from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import EditIcon from '@material-ui/icons/Edit';
import postService from "../Services/postService.js";
import tableIcons from "../constant/icons";
import urls from "../constant/url.js";

class PostPage extends React.Component{

    constructor(props){
        super(props);
        this.state={
            name : "",
            title :  "",
            articles : [],

        }
    }


    loadArticle=async(query)=>{
        return new Promise(async(resolve, reject) => {
            // prepare your data and then call resolve like this:
            let queryJSON ={
                page : query.page,
                pageSize : query.pageSize,
                search : query.search,
            } 

            let result = await postService.getArticle(queryJSON);
            result.data.articles.forEach((item)=>{
                item.commentCount = item.comments.length;

                let splitDesc = item.description.split(" ");
                if(splitDesc.length < 10){
                    item.shortDesc = item.description
                }else{
                    let sliceDesc = splitDesc.slice(0 , 10);
                    let joinDesc = sliceDesc.join(" ")+" ...";
                    item.shortDesc = joinDesc;
                }  
            })
            if(result.data.success){
                this.setState({
                    articles : result.data.articles,
                    isActive : false
                })
            }
            
            resolve({
                data: this.state.articles,// your data array
                page: query.page,// current page number
                totalCount: result.data.totalCount,// total row number
            });
        })

    }

    gotoCreate=()=>{
        this.props.history.push("/dashboard/create");
    }

    handleEditArticle=(e,id)=>{
        this.props.history.push("/dashboard/create/"+id)

    }
    render (){
        return <div>
            
            <MaterialTable
                columns={[
                    {title: 'Name' , render:rowData => 
                    <a href = {`${urls.frontURL}/${rowData.slug}`} target="#">{rowData.slug}</a>},
                    { title: 'Title', field: 'name' },
                    {title: "Views" , field : "views"},
                    {title : "Comments", field : "commentCount"},
                    { title: 'Description', field: 'shortDesc' },
                  ]}
                  actions={[
                    {
                        icon: AddBox,
                        isFreeAction:true,
                        tooltip: ' Add Article ',
                        onClick: (event, rowData) => {
                        this.gotoCreate()
                        }
                    },

                    {
                        icon: EditIcon,
                        tooltip: 'Edit Article',
                        onClick: (e, rowData) => {
                        this.handleEditArticle(e,rowData._id)
                        }
                    },

                
                    ]}
                    icons={tableIcons}
                    title="Post Management"
                    data= {this.loadArticle}
                    options={{
                        debounceInterval : 1000,
                        search: true,
                        pageSize : 5,
                        headerStyle: {
                            backgroundColor: '#01579b',
                            color: '#FFF'
                          }
                    }}
            />
        </div>
    }
}

export default PostPage;