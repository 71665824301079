import React,{useEffect,useState} from "react";
import MiniDrawer from "../Component/sidebar";
import useStyles from "../Component/sidebarStyle.jsx";
import { Switch, Route} from "react-router-dom";
import routes from "../Routes/routes";
import authService from "../Services/authService";
import LoadingPage from "../Pages/extra/loadingPage";
function Layout(props){

    const classes = useStyles();

    const[isLoading , setLoading]= useState(true);

    useEffect(()=>{
      async function verifyToken() {
        let result = await authService.verifyToken();
        console.log(result);
        if(result.data.success == false){
          props.history.push("/auth/login");
        }else{
          setLoading(false)
          props.login(result.data.userData.data);
        }
      }
      verifyToken();
    },[])

    return <div  className={classes.root}>
    
      {isLoading == false ?
        <>
          <MiniDrawer {...props}/>
          <main className={classes.content}>
          <div className={classes.toolbar} />
          <Switch>
            {routes.map((route)=>{
              return <Route path={route.main + route.path} render={(prop)=><route.component {...props} {...prop} />} exact/>
            })}
          </Switch>
          
          </main>
      </>
      :
      <LoadingPage/>
      }
    </div>
}

export default Layout;