import React from "react";
import { Switch,Route } from "react-router";
import loginRoute from "../Routes/loginRoute";
class AuthLayout extends React.Component{
    render (){
        return <div>
            <Switch>
                {loginRoute.map((route)=>{
                    return <Route path= { route.main + route.path} render={(prop)=><route.component {...prop} {...this.props}/>} exact></Route>
                })}
            </Switch>
        </div>
    }
}

export default AuthLayout;