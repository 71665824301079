import axios from "axios";
import urls from "../constant/url.js";

axios.interceptors.request.use(
    function(successfulReq) {
        let token =  'bearer ' + localStorage.getItem("atToken");
        successfulReq.headers['authorization'] = token;
        return successfulReq;
    }, 
    function(error) {
      return Promise.reject(error);
    }
  );

const postService = {

    postArticle : async (postData)=>{
        try{
            let result = await axios.post(urls.apiURL+"article",postData);
            return result;
        }
        catch(err){
            return err.response;
        }
    },

    getArticle : async (queryData)=>{
        try{
            let url = urls.apiURL+"article?page="+queryData.page+"&pageSize="+queryData.pageSize;
            
            if(queryData.search){
                url = url+"&search="+queryData.search;
            }
            let result = await axios.get(url);

            return result
        }catch(err){
            return err.response;
        }
    },

    getArticlebyId : async (id) =>{
        try{
            let result = await axios.get(urls.apiURL+"article/id/"+id);
            return result;
        }catch(err){
            return err.response;
        }
    },

    updateArticle : async (id,editedData)=>{
        try{
            let result = await axios.put(urls.apiURL+"article/"+id,editedData);
            return result;
        }catch(err){
            return err.response;
        }
        
    },

    uploadImage : async(formData)=>{
        try{
            let result = await axios.post(urls.apiURL+"utils/image/upload",formData);
            return result;
        }catch(err){
            return err.response;
        }
    },

    featureArticle : async(id,data)=>{
        console.log(data);
        try {
            let result = await axios.put(urls.apiURL+"article/feature/"+id,data);
            return result;
        }catch(err){
            return err;
        }
    },

    draftArticle : async (id)=>{
        try{
            let result = await axios.put(urls.apiURL+"article/"+id+"/draft",{});
            return result;
        }catch(err){
            return err.response;
        }
        
    },

    postReference : async (url)=>{
        try{
            let result = await axios.post(urls.apiURL+"utils/extract",{url});
            return result;
        }
        catch(err){
            return err.response;
        }
    },


}

export default postService;