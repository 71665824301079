import { Button, Container, Row, Col } from "react-bootstrap";
import React from "react";
import authService from "../../Services/authService.js";
import { toast } from "react-toastify";
import LoadingOverlay from 'react-loading-overlay';
import TextField from '@material-ui/core/TextField';
import { CircularProgress } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';


class LoginPage extends React.Component{

    constructor(props){
        super(props);
        this.state={
            email:"",
            password : "",
            isLoading : false,
            hidden : true,
        }
    }

    componentDidMount= async()=>{
        let result = await authService.verifyToken();
        if(result.data.success){
            this.props.login(result.data.userData.data);
            this.props.history.push("/dashboard");
        }
    }

    handleChange=(e)=>{
        this.setState({
            [e.target.name] : e.target.value
        })
    }

    handleLogin= async ()=>{
        console.log(this.props);
        this.setState({
            isLoading : true
        })

        let loginData = {
            email : this.state.email,
            password : this.state.password
        }
        let result = await authService.loginUser(loginData);
        
        if(result.data.success == false){
            toast.error(result.data.message);
            
        }else{
            let token = result.data.token;
            localStorage.setItem("atToken",token);
            this.props.login(result.data.userData);
            this.props.history.push("/dashboard");
        }
        this.setState({
            isLoading : false
        })
    }

    toggleShow=()=>{
        this.setState({
            hidden : !this.state.hidden
        })
    }

    render() {
        return <div className="formBox">
            
            <div className="loginForm clearfix" >

                <div className="imgDiv"></div>

                <div className="formDiv">

                    <Container>

                    <Row className="across"><h3>Across-Tradition</h3></Row>
                    
                    <Row>
                        <TextField  type="email" label="Enter Your Email" id="outlined-basic" 
                        variant="outlined" className="width100" size="medium"
                        name="email" onChange={this.handleChange}  />
                    </Row>
                    <br></br>
                    <Row>
                        <TextField type={this.state.hidden ? "password" : "text"} label="Enter Pasword" 
                        name="password" onChange={this.handleChange} id="outlined-basic" 
                        className="width100" variant="outlined" size="medium"/>
                    </Row>
                    <Row>
                        <a className="forgotPW ">Forgot Password?</a>
                    </Row>
                    <br></br>
                    
                    <Row>

                        <div className="clearfix pwDiv">
                        <div className="float-left ">
                           
                            {this.state.hidden == true ?
                                <div>
                                    <VisibilityIcon className="showPWicon" onClick={this.toggleShow}></VisibilityIcon>
                                    <span className="showPW" onClick={this.toggleShow}>Show Password</span>
                                </div>
                                :
                                <div>
                                    <VisibilityOffIcon className=" showPWicon" onClick={this.toggleShow}></VisibilityOffIcon>
                                    <span className="showPW" onClick={this.toggleShow}>Hide Password</span>
                                </div>
                            }
                                
                            
                        </div>
                    

                        <div className="float-right">
                            {this.state.isLoading == true ?

                            <div >
                                <CircularProgress/>
                            </div>
                            :
                            <div >
                                <Button className="loginBtn" onClick={this.handleLogin}>Login</Button>
                            </div>
                            }
                        </div>
                        </div>             
                    </Row>

                    </Container>
                </div>

            </div>

        </div>

    }
}

export default LoginPage;